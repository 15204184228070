<template>
  <span :id="model?.system?.id">
    <SmartLinkItemComponent
      v-if="featuredContent.length > 0"
      :id="model?.system?.id"
      class="featured-content-block"
      :component="!model?.system?.workflow"
    >
      <div class="container">
        <div class="row">
          <template v-if="isTwoColumn">
            <SmartLinkStandaloneElement
              class="col-12 col-lg-6 mb-3 title"
              :codename="type.elements.title.codename"
            >
              <h2>{{ model?.elements.title.value }}</h2>
            </SmartLinkStandaloneElement>
            <SmartLinkStandaloneElement
              class="col-12 col-lg-6 mb-3 subtitle"
              :codename="type.elements.subtitle.codename"
            >
              <p>{{ model?.elements.subtitle.value }}</p>
            </SmartLinkStandaloneElement>
          </template>
          <div v-else class="d-flex justify-content-center">
            <div class="col-lg-6 text-center mb-3">
              <SmartLinkStandaloneElement :codename="type.elements.title.codename">
                <h2>{{ model?.elements.title.value }}</h2>
              </SmartLinkStandaloneElement>
              <SmartLinkStandaloneElement :codename="type.elements.subtitle.codename">
                <p>{{ model?.elements.subtitle.value }}</p>
              </SmartLinkStandaloneElement>
            </div>
          </div>
        </div>
        <div class="row">
          <SmartLinkStandaloneElement :codename="type.elements.featured_content.codename">
            <div
              ref="scrollRef"
              class="featured-content-block__card-container d-flex"
              @touchstart="onTouchStart"
              @touchmove="onTouchMove"
              @touchend="handleSwipe"
            >
              <LinkedItemComponent
                v-for="item in featuredContent"
                :key="item?.system.id"
                :model="item"
              />
            </div>
          </SmartLinkStandaloneElement>
          <div
            class="featured-content-block__card-container__overlay-left"
            :style="{ display: showLeftArrow ? 'inherit' : 'none' }"
          />
          <div
            class="featured-content-block__card-container__overlay-right"
            :style="{ display: showRightArrow ? 'inherit' : 'none' }"
          />
          <div
            class="featured-content-block__card-container__arrow-left"
            :style="{ display: showLeftArrow ? 'inherit' : 'none' }"
            @click="leftClick"
          />
          <div
            class="featured-content-block__card-container__arrow-right"
            :style="{ display: showRightArrow ? 'inherit' : 'none' }"
            @click="rightClick"
          />
        </div>
      </div>
    </SmartLinkItemComponent>
  </span>
</template>
<script lang="ts" setup>
import {
  contentTypes,
  type CapabilitiesPageModel,
  type FeaturedContentBlockModel,
  type InsightPageModel,
  type InteriorPageModel,
  type PeoplePageModel
} from '~/models'
import delay from 'lodash/delay'

const scrollAmount = 300
const { scrollRef, scrollLeft, scrollRight } = useScroll()
const { showLeftArrow, showRightArrow, checkVisibility } = useSliderVisibility(scrollRef)
const updateArrows = () => delay(checkVisibility, 500)
const { onTouchStart, onTouchMove, onTouchEnd } = useSwipe()

const handleSwipe = () => {
  onTouchEnd((direction) => {
    if (scrollRef.value) {
      if (direction === 'right') {
        scrollLeft(scrollAmount)
        updateArrows()
      } else if (direction === 'left') {
        scrollRight(scrollAmount)
        updateArrows()
      }
    }
  })
}

const props = defineProps<{ model: FeaturedContentBlockModel }>()
const pageStore = usePageStore()
const type = contentTypes.featured_content_block

const isTwoColumn = computed(() => {
  const option = firstOrDefault(props.model?.elements.textAlignment.value)
  if (option) {
    return option.codename == 'two_column'
  }
  return 'centered'
})

const featuredContent = computed(() => {
  return pageStore.getListAs<
    InsightPageModel | CapabilitiesPageModel | InteriorPageModel | PeoplePageModel
  >(props.model?.elements.featuredContent.value)
})

const leftClick = () => {
  scrollLeft(scrollAmount)
  updateArrows()
}

const rightClick = () => {
  scrollRight(scrollAmount)
  updateArrows()
}
</script>
